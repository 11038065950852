var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","sm":"5"}},[_c('v-card',{staticStyle:{"font-size":"16px"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Creating an affiliate link")]),_c('v-spacer')],1),_c('validation-observer',{ref:"createManagerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{staticStyle:{"padding":"0 30px"},on:{"submit":function($event){$event.preventDefault();return _vm.submitCreateLink(reset)}}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|max:20|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Enter your First Name","required":""},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max:20|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Enter your Last Name","required":""},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid,"text":""}},[_vm._v(" Generate ")])],1)],1)]}}])}),(_vm.url_referral)?[_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.url_referral)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.copyUrl}},[_vm._v(" Copy ")])],1)]:_vm._e()],2)],1)],1),_c('v-divider',{staticStyle:{"margin-top":"20px"}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","dense":"","headers":_vm.headers,"items":_vm.managers,"items-per-page":50,"footer-props":{
            'items-per-page-options': [10, 20, 40, 60, 80, 100],
        }},scopedSlots:_vm._u([{key:"item.affiliate_link",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyText(
                                _vm.invest_url +
                                    "signup?referral_code=" + (item.code)
                            )}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.invest_url + "signup?referral_code=" + (item.code))+" ")])]}}],null,true)},[_c('span',[_vm._v("Click to copy")])])]}},{key:"item.code",fn:function(ref){
                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyText(item.code)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Click to copy")])])]}},{key:"item.details",fn:function(ref){
                            var item = ref.item;
return [(item.count_user > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.getManagerUsers(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{model:{value:(_vm.filter.user_name),callback:function ($$v) {_vm.$set(_vm.filter, "user_name", $$v)},expression:"filter.user_name"}})],1),_c('td',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date range","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDate}},[_vm._v(" Clear ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('td'),_c('td',[_c('v-text-field',{model:{value:(_vm.filter.code),callback:function ($$v) {_vm.$set(_vm.filter, "code", $$v)},expression:"filter.code"}})],1),_c('td',[_c('v-text-field',{model:{value:(_vm.filter.count_user),callback:function ($$v) {_vm.$set(_vm.filter, "count_user", $$v)},expression:"filter.count_user"}})],1)])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.open_dialog),callback:function ($$v) {_vm.open_dialog=$$v},expression:"open_dialog"}},[_c('v-card',{staticStyle:{"font-size":"16px"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Manager Users")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Total Shares: "+_vm._s(_vm.totalShares)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Total Amount: € "+_vm._s(_vm.totalPrice)+" ")])]),_c('v-data-table',{attrs:{"loading":_vm.loadingUser,"loading-text":"Loading... Please wait","dense":"","items-per-page":10,"footer-props":{
                    'items-per-page-options': [10, 20, 30],
                },"headers":_vm.headersUser,"items":_vm.loadingUser ? [] : _vm.manager_users},scopedSlots:_vm._u([{key:"item.quantity_lots",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_lots ? _vm.numberWithSpaces(item.quantity_lots) : '-')+" ")]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount ? _vm.numberWithSpaces(item.amount) : '-')+" ")]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }